<template>
  <nav class="relative w-full z-10">
    <div class="brace max-lg:px-0 lg:py-6">
      <div class="bg-green text-white transition-height duration-300 shadow-[0_0_15px_0_rgba(0,0,0,0.15)] rounded-b-2xl lg:rounded-xl">
        <div class="flex pl-4 pr-2 py-3 gap-2 lg:px-6 lg:py-4">
          <nuxt-link class="block mr-auto my-auto text-2xl sm:text-3xl font-bold font-degular" :to="localePath('quit')">
            <img :src="logo" style="filter: invert(1);" class="w-24"/>
          </nuxt-link>

          <div class="hidden lg:flex gap-10 justify-center whitespace-nowrap font-degular">
            <nuxt-link class="flex-none my-auto font-bold" :to="localePath('quit-subpage-index-pro')">{{ t('navbar.health_pro') }}</nuxt-link>
            <nuxt-link class="flex-none my-auto font-bold" :to="localePath('quit-subpage-index-hesitate-index')">{{ t('navbar.prepare') }}</nuxt-link>
          </div>

          <nuxt-link class="flex-none btn btn--outline btn--invert h-9 lg:ml-auto my-auto"
                     :to="locale == 'fr' ? '/jarrete/connexion' : '/iquitnow/login'"
                     external>{{ t('navbar.connect') }}</nuxt-link>

          <div class="flex flex-none lg:hidden">
            <button class="btn btn--outline btn--invert flex-none w-9 h-9 my-auto p-0 [&.open]:drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]"
                    :class="{ open: headerOpen }"
                    type="button"
                    @click="headerOpen = !headerOpen">

              <svg class="inline-block w-[1.375rem] h-[1.375rem]" width="22" height="22">
                <path d="M1,11L21,11" stroke="currentColor" fill="none" stroke-width="2" stroke-linecap="round"/>
                <path d="M11,1L11,21" stroke="currentColor" fill="none" stroke-width="2" stroke-linecap="round"
                      class="transition-transform origin-center scale-y-100 [.open_&]:scale-y-0" />
              </svg>
            </button>
          </div>
        </div>

        <div v-if="headerOpen" class="flex flex-wrap lg:hidden w-full justify-center text-white py-4 font-degular shadow-[inset_0_4px_6px_rgba(0,0,0,0.1)]">
          <nuxt-link class="flex-none my-auto px-3 font-bold" :to="localePath('quit-subpage-index-pro')">{{ t('navbar.health_pro') }}</nuxt-link>
          <nuxt-link class="flex-none my-auto px-3 font-bold" :to="localePath('quit-subpage-index-hesitate-index')">{{ t('navbar.prepare') }}</nuxt-link>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { ref } from "vue";

export default {
  setup() {
    const nuxtApp = useNuxtApp();
    const { t, locale, locales } = useI18n({
      useScope: "local",
    });
    const localePath = useLocalePath();
    const headerOpen = ref(false);

    return { t, locale: locale.value, localePath, nuxtApp, headerOpen };
  },
  computed: {
    logo() {
      return this.locale === "fr"
        ? "/images/quit/logo_fr.png"
        : "/images/quit/logo_en.png"
    }
  }
};
</script>

<i18n lang="json">
{
  "fr": {
    "navbar": {
      "i_quit": "J'ARRÊTE.",
      "health_pro": "Professionnels de la santé",
      "prepare": "Je me prépare",
      "connect": "Je me connecte"
    }
  },
  "en": {
    "navbar": {
      "i_quit": "I QUIT NOW.",
      "health_pro": "Health Care Providers",
      "prepare": "Getting Ready",
      "connect": "Login"
    }
  }
}
</i18n>
